$primary:             #20a8d8;
$success:             #4dbd74;
$info:                #63c2de;
$warning:             #f8cb00;
$danger:              #f86c6b;

$fa-font-path: '/fonts';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// $navbar-height
